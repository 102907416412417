import { ComboBox, IconButton } from 'office-ui-fabric-react';
import {Dropdown} from 'office-ui-fabric-react/lib/Dropdown'
import {TextField} from 'office-ui-fabric-react/lib/TextField'
import React from 'react'
import {DatePicker} from 'office-ui-fabric-react'
import Button from '@material-ui/core/Button'
import {Grid, Tooltip} from '@material-ui/core'
import { getAnalystCode, getAnalysts } from '../Api'
import { getUserOptions } from '../AuthService';
import GetAppIcon from "@material-ui/icons/GetApp";

const platforms = [
    {
        text: 'All',
        key: '',
    },
    {
        text: 'Facebook',
        key: 'facebook',
    },
    {
        text: 'Twitter',
        key: 'twitter',
    },
    {
        text: 'Gemini',
        key: 'gemini',
    },
    {
        text: 'Baidu',
        key: 'baidu',
    },
    {
        text: 'Outbrain',
        key: 'outbrain',
    },
]

class SearchPanel extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        analystOptions: [],
        internalYes: '',
        internalNo: '',
        prefixByAnalyst: this.props.state['prefix'].value,
        disabledSearch: false,
        internalKey: this.props.state.internal.value || 'ANY'
      };
    }

    componentDidMount() {
      this.props.store.dispatch({type: 'searchPanelMounted'})
      this.loadAnalystOptions();
    }

    componentDidUpdate(prevProps) {
      const { analyst } = this.props.state;
    
      if (analyst.value && analyst.value !== prevProps.state.analyst.value) {
        this.updatePrefixAndFilters(analyst.value, 'prefix', 'internal',  'ANY');
      }
    }
    
    updatePrefixAndFilters(value, prefixKey, filter, filterKey) {
      this.setState({disabledSearch: true})
      this.getPrefixByAnalyst(value)
        .then(prefix => {
          this.setState({ prefixByAnalyst: prefix });
          this.updateFilter(prefixKey, prefix);
          this.setState({internalKey: filterKey})
          this.updateFilter(filter, filterKey);

        })
        .then(() => this.setState({disabledSearch: false}))
        .catch(error => {
          console.error('Failed to load partner code:', error);
        })
    }

    async loadAnalystOptions() {
      try {
        const analysts = await getAnalysts();

        const userOptions = getUserOptions();
        const insPrefixes = userOptions.ins_prefix_list;

        let filteredAnalysts = [];
        if (insPrefixes !== '*'){
          filteredAnalysts = analysts.filter(analyst => {
            const codes = analyst.partner_codes.split(',');
            return codes.some(code => insPrefixes.includes(code.trim()));
          });
        } else {
          filteredAnalysts = analysts;
        }
        const filteredAnalystsOptions = filteredAnalysts.map(analyst => analyst.partner_name)
        this.setState({ analystOptions: filteredAnalystsOptions });

        const internal_yes = analysts.filter(analyst => analyst.biz_model === 'Content B2C');
        const partnerCodes_yes = internal_yes.map(item => item.partner_codes).join(',');
        const internal_no = analysts.filter(analyst => analyst.biz_model !== 'Content B2C');
        const partnerCodes_no = internal_no.map(item => item.partner_codes).join(',');
        if (insPrefixes !== '*'){
          const filteredYes = partnerCodes_yes.split(',').filter(prefix => insPrefixes.includes(prefix)).join(',');
          const filteredNo = partnerCodes_no.split(',').filter(prefix => insPrefixes.includes(prefix)).join(',');
          this.setState({internalYes: filteredYes, internalNo: filteredNo})
        } else {
          this.setState({internalYes: partnerCodes_yes, internalNo: partnerCodes_no})
        }
      } catch (error) {
        console.error('Failed to load analyst options:', error);
      }
    }

    async getPrefixByAnalyst(name) {
      try {
        const prefix = await getAnalystCode(name);
        return prefix;
      } catch (error) {
        console.error('Failed to load analyst code:', error);
      }
    }

    handleClear = (field) => {
      this.props.store.dispatch({
        type: 'updateFilter',
        field: field,
        value: '',
      })
    };

    updateInternal(ev, item){
      this.setState({internalKey: item.key})
      this.updateFilter('analyst', '');
      switch (item.key) {
        case 'YES':
          this.updateFilter('prefix', this.state.internalYes);
          this.updateFilter('internal', item.key);
          break;
        case 'NO':
          this.updateFilter('prefix', this.state.internalNo);
          this.updateFilter('internal', item.key);
          break;
        default:
          this.updateFilter('prefix', '');
          this.updateFilter('internal', item.key);
          break;
      }

    }
    updateDatePeriod(ev, item) {
        this.props.store.dispatch({
            type: 'updateDatePeriod',
            datePeriod: item.key,
        })
    }

    updateCustomDate(field, date) {
        this.props.store.dispatch({
            type: 'updateCustomDate',
            field,
            date,
        })
    }

    updateFilter(field, value) {
        this.props.store.dispatch({
            type: 'updateFilter',
            field,
            value,
        })
    }

    trapEnter(e) {
        if (e.keyCode === 13) {
            e.preventDefault()
            this.props.store.dispatch({
                type: 'triggerSearch',
                filters: this.props.state,
            })
        }
        return e
    }

  render() {
    return (
      <div className={bcgrnd} onKeyUp={(e) => this.trapEnter(e)}>
        <Grid container spacing={2} style={{ padding: '16px 32px' }}>
          <Grid item xs={12} sm={12} >
            <Grid container spacing={2}>
              <Grid item xs={6} sm={2} md={2} >
                <div style={{ position: 'relative' }}>
                  <ComboBox
                    label="Analyst"
                    allowFreeform={false}
                    autoComplete="on"
                    options={this.state.analystOptions.map((name) => ({
                      text: name,
                      key: name,
                    }))}
                    onChange={(ev, item) => this.updateFilter('analyst', item.key)}
                    selectedKey={this.props.state.analyst.value}
                    // useComboBoxAsMenuWidth
                    styles={{
                      callout: {
                        maxHeight: '500px',
                      },
                    }}
                  />
                  {this.props.state.analyst.value && (
                    <IconButton
                      style={{
                        position: 'absolute',
                        right: '0px',
                        top: '20%',
                        transform: 'translateY(-50%)',
                      }}
                      iconProps={{ iconName: 'Clear' }}
                      title="Clear"
                      ariaLabel="Clear"
                      onClick={() => {
                        this.handleClear('analyst'); 
                        this.handleClear('prefix');
                      }}
                    />
                  )}
                </div>
              </Grid>
              <Grid item xs={6} sm={2} md={2} >
                <Dropdown
                  label="Internal"
                  options={[
                    { key: 'ANY', text: 'Any' },
                    { key: 'YES', text: 'Yes' },
                    { key: 'NO', text: 'No' },
                  ]}
                  selectedKey={this.state.internalKey}
                  onChange={this.updateInternal.bind(this)}
                  />
              </Grid>
              <Grid item xs={6} sm={4} md={2} >
                <ComboBox
                  label="Platform"
                  allowFreeform={false}
                  autoComplete="on"
                  options={platforms}
                  onChange={(ev, item) => this.updateFilter('platform', item.key)}
                  selectedKey={this.props.state.platform.value}
                  useComboBoxAsMenuWidth
                />
              </Grid>
              <Grid item xs={6} sm={3} md={3}>
                <Dropdown
                  label="Active Time"
                  options={[
                    { key: 'TODAY', text: 'Today' },
                    { key: 'YESTERDAY', text: 'Yesterday' },
                    { key: 'LAST_7_DAYS', text: 'Last 7 days' },
                    { key: 'LAST_14_DAYS', text: 'Last 14 days' },
                    { key: 'LAST_30_DAYS', text: 'Last 30 days' },
                    { key: 'THIS_MONTH', text: 'This month' },
                    { key: 'LAST_MONTH', text: 'Last month' },
                    { key: 'THIS_YEAR', text: 'This year' },
                    { key: 'ALL_TIME', text: 'All time' },
                    { key: 'CUSTOM', text: 'Custom' },
                  ]}
                  selectedKey={this.props.state.datePeriod}
                  onChange={this.updateDatePeriod.bind(this)}
                />
              </Grid>
              <Grid item xs={6} sm={3} md={3}>
                {this.props.state.datePeriod === 'CUSTOM' && (
                  <>
                    <DatePicker
                      label="from"
                      placeholder="Select a date..."
                      ariaLabel="Select a date"
                      value={this.props.state.startDate && this.props.state.startDate.toDate()}
                      onSelectDate={(d) => this.updateCustomDate('startDate', d)}
                    />
                    <DatePicker
                      label="to"
                      placeholder="Select a date..."
                      ariaLabel="Select a date"
                      value={this.props.state.endDate && this.props.state.endDate.toDate()}
                      onSelectDate={(d) => this.updateCustomDate('endDate', d)}
                    />
                  </>
                )}
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={6} sm={2} md={2}>
            <TextField
              label="Spend"
              prefix=">="
              type="number"
              name="spend"
              value={this.props.state.spend.value.from || ''}
              onChange={(e) => this.updateFilter('spend', { from: e.target.value })}
            />
          </Grid>
          <Grid item xs={6} sm={4} md={2}>
            <TextField
              label="Prefix"
              description="*Enter comma-separated values"
              name="prefix"
              value={this.props.state['prefix'].value}
              onChange={(e) => this.updateFilter('prefix', e.target.value)}
            />
          </Grid>
          <Grid item xs={6} sm={4} md={3}>
            <TextField
              label="Search in ad name"
              description="*Enter comma-separated values"
              name="ad"
              value={this.props.state.ad.value}
              onChange={(e) => this.updateFilter('ad', e.target.value)}
            />
          </Grid>
          <Grid item xs={6} sm={4} md={2}>
            <TextField
              label="Search in text"
              description="*Enter comma-separated values"
              name="text"
              value={this.props.state.text.value}
              onChange={(e) => this.updateFilter('text', e.target.value)}
            />
          </Grid>
          <Grid item xs={6} sm={4} md={2}>
            <TextField
              label="Search in title"
              description="*Enter comma-separated values"
              name="title"
              value={this.props.state.title.value}
              onChange={(e) => this.updateFilter('title', e.target.value)}
            />
          </Grid>
            <Grid item sm={12} container direction={'row'} spacing={1} alignItems={'center'}>
              <Grid item sm={1} align='left'>
                  <Button
                    color="primary"
                    fullWidth
                    variant="contained"
                    style={{marginRight: 5}}
                    disabled={this.state.disabledSearch}
                    onClick={() =>
                        this.props.store.dispatch({
                            type: 'triggerSearch',
                            filters: this.props.state,
                        })
                    }
                  >
                    Search
                  </Button>
              </Grid>
              <Grid item align='left'>
                <Button
                  fullWidth
                  variant="outlined"
                  onClick={(e) => {
                      this.props.store.dispatch({type: 'bulkFb', e})
                  }}
                >
                  Bulk Facebook
                </Button>
              </Grid> 
              <Grid item align='left'>
                <Button
                  fullWidth
                  variant="outlined"
                  onClick={(e) => {
                      this.props.store.dispatch({type: 'bulkTw', e})
                  }}
                >
                  Bulk Twitter
                </Button>
              </Grid>
                <Tooltip title={'Download result to xlsx'}>
                    <IconButton
                        color="default"
                        variant="outlined"
                        onClick={(e) => {
                            this.props.store.dispatch({ type: 'saveToFile', e })
                        }}
                    >
                        <GetAppIcon/>
                    </IconButton>
                </Tooltip>
            </Grid>
              <div className="clearfix"></div>
          </Grid>
        </div>
        )
    }
}

const bcgrnd = window.location.href.indexOf('localhost') !== -1 ? 'bckgrnd' : ''

export default SearchPanel
